import React, { Component } from 'react';
import {
	Pagination,
	PaginationItem,
	PaginationLink,
	Collapse,
} from 'reactstrap';
import { connect } from 'react-redux';

class ClientPagination extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentPage: props.currentPage,
			toggleQuestion: 0,
		};
	}

	setToggequestion = (e, i) => {
		e.preventDefault();
		this.setState((prev) => ({
			toggleQuestion: prev.toggleQuestion === i ? -1 : i,
		}));
	};

	handleClick = (e, index) => {
		e.preventDefault();
		this.setState({
			currentPage: index,
		});
		document.body.scrollTop = 100;
		document.documentElement.scrollTop = 100;
	};

	render() {
		// const [toggleQuestion, setToggequestion] = useState(0);

		const { pageSize, pageCount, tranformations: data } = this.props;
		const { currentPage, toggleQuestion } = this.state;

		let pageNumbers = [];
		for (let i = 0; i < pageCount; i++) {
			pageNumbers.push(
				<PaginationItem key={i} active={currentPage === i ? true : false}>
					<PaginationLink onClick={(e) => this.handleClick(e, i)} href='#'>
						{i + 1}
					</PaginationLink>
				</PaginationItem>
			);
		}
		const paginatedData = data.slice(
			currentPage * pageSize,
			(currentPage + 1) * pageSize
		);

		return (
			<React.Fragment>
				{paginatedData.map((datum, i) => (
					<div className='col-md-6' key={i}>
						<div className='row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative'>
							<div className='col p-4 d-flex flex-column position-static'>
								<h3 className='mb-0'>{datum.name}</h3>
								<p>{datum.content_1}</p>
								<div className='title-bar full-width mb20'>
									<img
										src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${datum.img_1}`}
										alt='title-img'
									/>
								</div>
								<p>
									{datum.content_2}{' '}
									{toggleQuestion === i + 1 ? (
										<span className='text-dark'>{datum.read_more}</span>
									) : (
										<span id='dots'>...</span>
									)}
								</p>
								<span onClick={(e) => this.setToggequestion(e, i + 1)}>
									{toggleQuestion === i + 1 ? 'Read less' : 'Read more'}
								</span>
							</div>
							<div className='col-auto d-none d-lg-block'>
								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${datum.img_2}`}
									alt='transformation img'
									width='200'
									height='250'
								/>
							</div>
						</div>
					</div>
				))}

				<React.Fragment>
					<div className='container'>
						<div className='row'>
							<Pagination className='justify-content-center-2 Page navigation example'>
								<PaginationItem>
									<PaginationLink
										onClick={(e) => this.handleClick(e, currentPage - 1)}
										previous
										href='#'
									/>
								</PaginationItem>
								{pageNumbers}

								<PaginationItem disabled={currentPage >= pageCount - 1}>
									<PaginationLink
										onClick={(e) => this.handleClick(e, currentPage + 1)}
										next
										href='#'
									/>
								</PaginationItem>
							</Pagination>
						</div>
					</div>
				</React.Fragment>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (st) => ({
	tranformations: st.app.transformations,
});

export default connect(mapStateToProps)(ClientPagination);
