import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Container, Row, Col } from 'reactstrap';

import './style.scss';
import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import ClientPagination from './ClientPagination';

export default function ClientTransformation() {
	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 6,
		focusOnSelect: true,
		arrows: true,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settings_1 = {
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const { transformation_pics, youtube_links } = useSelector((st) => st.app);

	return (
		<>
			<Header2 />

			<div className='about-sec padding-short'>
				<Container>
					<Row>
						<Col md='12'>
							<div className='section-head'>
								<h3 className='head-font'>Client Transformation</h3>
							</div>
						</Col>
						<Col md='12'>
							<ol className='breadcrumb'>
								<li>
									<Link to='/'>Home</Link>
								</li>
								<li className='section-head'>></li>
								<li className='section-head'>Client Transformation</li>
							</ol>
						</Col>
					</Row>
				</Container>
			</div>
			<hr></hr>

			<div className='contact-area pad90'>
				<div className='container-value'>
					<div className='row mb-2'>
						<ClientPagination pageSize={8} pageCount={4} currentPage={0} />
					</div>
				</div>
			</div>

			<div className='portfolio-area title-white bg1 parallax pad90 video-review'>
				<div className='video-set'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center'>
								<div className='title-bar full-width mb20'>
									<img
										src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/ttl-bar.png`}
										alt='title-img'
									/>
									<h3 className='rockstarts'>
										Hear directly from my rockstars
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Slider {...settings_1}>
				{youtube_links.map((e, i) => (
					<div className='testimonial-item' key={i}>
						<iframe
							src={e.url}
							title='YouTube video player'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						></iframe>
					</div>
				))}
			</Slider>

			<div className='testimonial-down'>
				<Slider {...settings}>
					{transformation_pics?.map((e, i) => (
						<div key={i}>
							<img
								src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${e.img}`}
								alt=''
								className='img img-fluid'
							/>
						</div>
					))}
				</Slider>
			</div>

			<Footer />
		</>
	);
}
